import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useCookies } from 'react-cookie';
import { Box, TextField, Button, Typography, Modal, Input } from '@mui/material';
import Avatar from 'boring-avatars';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faSmile, faPaperPlane, faRobot, faSackDollar, faTimes, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import useSound from 'use-sound';
import boopSfx from '../styles/msg.mp3';  // Path to your sound file for messages
import connectSfx from '../styles/connect.mp3';  // Path to your sound file
import ohfuck from "../styles/img/ohf.png";
import { Container, borderBottom, borderRadius, width } from '@mui/system';
import soltxt from "../styles/img/soltxt.png";
import { display } from '@mui/system/Box';
import { faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import EmojiPicker from 'emoji-picker-react';  // Importa il componente EmojiPicker


// const socket = io('http://localhost:5000', { withCredentials: true });
const socket = io('https://soldegens-server-6bc0c9f4fa58.herokuapp.com', { withCredentials: true });

function Chat() {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [cookies, setCookie] = useCookies(['username']);
    const [username, setUsername] = useState('');
    const [openModal, setOpenModal] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const messagesEndRef = useRef(null);
    const [playConnect] = useSound(connectSfx);
    const [playMessage] = useSound(boopSfx);
    const [chatVisible, setChatVisible] = useState(true);
    const [userCount, setUserCount] = useState(0);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

const toggleChat = () => {
    setChatVisible(!chatVisible);
};


const onEmojiClick = (emojiObject, event) => {
    console.log("Emoji selected:", emojiObject); // Controlla nel console log l'oggetto dell'emoji
    if (emojiObject && emojiObject.emoji) {
        setMessage(prevMessage => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false); // Nascondi l'emoji picker dopo la selezione
    }
};


     useEffect(() => {
        socket.on('receive_message', (data) => {
            setMessages(prevMessages => [...prevMessages, data]);
            playMessage();
        });
        socket.on('user_connected', playConnect);

        socket.on('user_count', (count) => {
            setUserCount(count);
            socket.off('user_count');

        });

        return () => {
            socket.off('receive_message');
            socket.off('user_connected');
        };
    }, [playMessage, playConnect]);


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        setUsername(cookies.username || '');
        setOpenModal(!cookies.username);
    }, [cookies.username]);

    const sendMessage = () => {
        if (!message.trim()) return;
        const messageData = {
            author: username,
            message,
            time: new Date().toLocaleTimeString(),
        };

        socket.emit('send_message', messageData, (response) => {
            if (response && response.error) {
                setErrorMessage(response.error);
                setTimeout(() => setErrorMessage(''), 5000);
            } else {
                setMessage('');
            }
        });
    };

    const handleCloseModal = () => {
        if (username.trim()) {
            setCookie('username', username, { path: '/' });
            setOpenModal(false);
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: "flex",
        flexDirection: "column",
        alignItems: 'center', 
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'black',
        borderRadius: "1rem",
        boxShadow: '0px 0px 20px 0px #f15cfa',
        p: 4,
    };


    return (
        <>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={soltxt} alt='soltxt' style={{width: "120px", objectFit: "contain"}}/>
                    </Typography>
                    <div style={{display: 'flex', gap: "1rem", alignItems: "center", justifyContent: 'space-around'}}>
                    <a style={{color: "white"}} href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp' target='blank' rel='noreferrer noopener'>
                    <FontAwesomeIcon icon={faSackDollar}/>
                    </a>
                    <a style={{color: "white"}} href='https://twitter.com/sol_degens_' target='blank' rel='noreferrer noopener'>
                        <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                        <a style={{color: "white"}} href='https://t.me/soldegens_coin' target='blank' rel='noreferrer noopener'>
                        <FontAwesomeIcon icon={faTelegram}/>
                        </a>
                        <a style={{color: "white"}} href='https://t.me/soldegens_coin' target='blank' rel='noreferrer noopener'>
                        <FontAwesomeIcon icon={faRobot}/>
                        </a>
                    </div>
                    <Typography id="modal-modal-title"  style={{marginTop: "1rem", fontSize: "14px"}}>
                    Welcome Anon, please set up your username.
                    </Typography>
                    <Input
                        autoFocus
                        style={{
                            borderRadius: "0.5rem",
                            marginTop: "1.5rem"}}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleCloseModal()}
                        fullWidth
                    />
                </Box>
            </Modal>

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

{chatVisible && (

            <Box sx={{ position: 'fixed', bottom: 20, right: 20, width: 300,

                boxShadow: "0px 0px 20px 0px #f15cfa", border: "1px solid white",
                background: 'black',
                padding: '30px', borderRadius: '0.7rem' }}>
                    <div style={{ display: 'flex', 

                    alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={{fontSize: "18px", fontWeight: 'bold',

                                  textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      

                    }}>-$DEGEN Chat-</Typography>
                        <Typography ><FontAwesomeIcon icon={faUserAlt}
                             style={{fontSize: "18px", fontWeight: 'bold',
                             textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
                            marginRight: "2px"}}/> {userCount}</Typography>

                        <FontAwesomeIcon style={{backgroundColor: 'red',
                        borderRadius: '.3rem',
                        color: 'white', padding: '3px'}} icon={faTimes} onClick={toggleChat} />
                    </div>
                    <div style={{                        marginTop: '3px',                                borderBottom: "1px solid #f15cfa",}}></div>

                    <Box component="div" sx={{ marginBottom: '1rem', marginTop: "0.5rem", maxHeight: messages.length === 0 ? 'auto' : '420px', overflowY: 'auto' }}>
    {messages.length === 0 ? (
        <div style={{display: "flex", gap: "2rem", flexDirection: 'column'}}>
        <Typography style={{
            color: "white",
            borderRadius: '0.5rem',
            padding: '20px',
            backgroundColor: 'black', 
            textAlign: "center", 
            marginTop: "20px",
            fontSize: "16px",
            fontWeight: 'bold'
        }}>
           $SOL Degens LIVE Chat <FontAwesomeIcon icon={faMessage}/>
           </Typography>

           <center><img src={soltxt} style={{width: '180px', objectFit: 'contain'}}/></center>
           <Typography style={{
            color: "white",
            borderRadius: '0.5rem',
            padding: '10px',
            backgroundColor: 'black', 
            textAlign: "center", 
            marginTop: "20px",
            fontSize: "16px",
            fontWeight: 'bold'
        }}>
           Waiting for messages...
</Typography>
        </div>
    ) : (
        messages.map((message, index) => (
            <Box key={index} style={{
                borderBottom: "1px solid white",
                backgroundColor: 'black',
                // background: message.author === username ? "rgba(241,92,250, 0.3)" : "black", // Different background if the user is the author
                marginTop: "0.5rem",
                borderRadius: "0rem", 
                padding: '8px'
            }}>
                <div style={{
                    background: message.author === username ? "rgba(241,92,250, 0.3)" : "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(48,48,48,1) 100%)",
                    
                    padding: '1px', 
                    width: '100%', 
                    justifyContent: "space-between",
                    display: 'flex', 
                    borderRadius: "0.3rem",
                    alignItems: "center"
                }}>
                    <div style={{display: 'flex', alignItems: "center"}}>
                    <Avatar
                        size={20}
                        name={message.author}
                        variant="beam"
                        colors={["#C13584", "#833AB4", "#5851DB", "#405DE6", "#20BFA9"]}
                    />
                    <Typography style={{
                        fontWeight: "bold", 
                        color: message.author === username ? 'white' : "limegreen",
                        marginLeft: '8px'
                    }} variant="caption" display="block">
                        ━ {message.author === username ? 'You' :  `${message.author}`}
                    </Typography>
                    </div>
                    <Typography variant="caption" display="block" style={{
                        color: "grey",
                        marginRight: '3px'
                    }}> {message.time}
                    </Typography>
                </div>
                <Typography style={{marginTop: "0.3rem"}} variant="body2">{message.message}</Typography>
            </Box>
        ))
    )}
    
    <div ref={messagesEndRef} />
</Box>

                <div style={{
                    backgroundColor: "rgba(241,92,250, 0.6)", padding: "5px", borderRadius: '0.3rem',
                            boxShadow: "0px 0px 5px 0px #f15cfa", border: "1px solid white",
                    display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                          

                <TextField
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                  <div style={{flexDirection: "column",
                            display: "flex"}}>
                <Button style={{height: "20px"}} onClick={() => setShowEmojiPicker(prev => !prev)}>
                    <FontAwesomeIcon style={{color: "white"}} icon={faSmile} />
                </Button>
                {showEmojiPicker && (
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                )}
            </div>
                <FontAwesomeIcon icon={faPaperPlane} onClick={sendMessage} style={{
                 
                 color: 'black'}}>Send</FontAwesomeIcon>
                </div>
            </Box>
             )}
             
             {!chatVisible && (
                 <Button onClick={toggleChat} style={{ backgroundColor: '#833AB4', padding: "10px",
                  position: 'fixed', color: "white", fontWeight: "bold", bottom: 20, right: 20 }}>
                     Open Chat <FontAwesomeIcon icon={faMessage} style={{marginLeft: "10px"}}/>
                 </Button>
             )}
        </>
    );
}

export default Chat;
