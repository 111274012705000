import React, { useState, useEffect, useRef } from 'react';
import { Container,  CardContent, Typography, Grid, Link as MuiLink, ardContent, Card, Box, Link, CardMedia, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { animated } from 'react-spring';
import { useSpring } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faDollarSign, faLink, faUserAlt, faFireBurner, faFire } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import pump from "../styles/img/pump.webp";
import soltxt from "../styles/img/soltxt.png";
import {motion} from "framer-motion";

import KingOfTheHill from '../components/KOTH';
import format from 'date-fns/format';  // Make sure to install date-fns if not already installed


const AnimatedCard = animated(Card);

const PumpMonitor = () => {
  const [username, setUsername] = useState('');
  const [messages, setMessages] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('');
  const [typingUsers, setTypingUsers] = useState([]);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [latestToken, setLatestToken] = useState(null);
  const [lastTokenId, setLastTokenId] = useState('');
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery(`(max-width: 600px)`);
  const messageListRef = useRef();

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  });

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 3000);
    return () => clearInterval(interval);
  }, []);

const fetchData = async () => {
    try {
      const response = await axios.get('https://client-api-2-74b1891ee9f9.herokuapp.com/coins/latest', {
        withCredentials: false  // Imposta false solo per questa richiesta
      });
      if (response.data && response.data.mint !== lastTokenId) {
        setLatestToken(response.data);
        setLastTokenId(response.data.mint);
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };


  if (loading) return <p>Loading...</p>;

  const formatCreatorAddress = (address) => {
    return address ? `${address.slice(0, 4)}...${address.slice(-4)}` : '';
  };

  const convertLamportsToSol = (lamports) => {
    return lamports / 1e9; // Convert lamports to SOL
  };

  const formatDate = (timestamp) => {
    return format(new Date(timestamp), "PPpp"); // Using date-fns to format the timestamp
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

return (
  <Box sx={{ display: 'flex', flexDirection: "column", alignItems: isMobile ? "center" : undefined}}>
        {isMobile && (
      <img src={soltxt} alt='SOL text logo' style={{width: "120px", objectFit: 'contain'}} />
      )}


    <Card sx={{
      marginTop: " 1rem",
      justifyContent: "space-around", boxShadow: "none", alignItems: "center", display: 'flex'}}>
      <Typography variant="h4" gutterBottom sx={{ fontSize: isMobile ? '18px' : "20px", fontWeight: 'bold', color: "white" }}>
        <Link style={{color: '#f15cfa'}} to='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp'>pump.fun</Link> <img src={pump} alt='Pump logo' style={{ width: "20px" }} /> - Token Monitor
      </Typography>
      <Typography style={{textAlign: 'center', borderRadius: "0.3rem", backgroundColor: "red", 
      padding: "5px", fontWeight: "bold", color: "white", fontSize: isMobile ? '10px' : '14px'}}>
        💥 pump.fun monitors now for TELEGRAM! <FontAwesomeIcon style={{marginRight: "10px"}} icon={faTelegram}></FontAwesomeIcon>

      </Typography>
      {!isMobile && (
      <img src={soltxt} alt='SOL text logo' style={{height: "50px" }} />
      )}
    </Card>

    <div style={{border:" 1px solid #f15cfa", display: 'flex',
    alignItems: 'center', justifyContent: 'center',
    marginTop: '0.5rem', marginBottom: '1rem',
    width: "auto"}}/>


<Container style={{ display: "flex", alignItems: "center",
marginTop: "1rem",
justifyContent: "space-around"}}>


      </Container>

    <Container sx={{
      alignItems: isMobile ?  "center" : undefined,
      display: 'flex', gap: isMobile ? 2 : 5, flexDirection: isMobile ? 'column' :undefined, justifyContent: 'space-around'}}>

    <Container style={{display: 'flex', flexDirection: "column"}}>
    <Typography variant="h4"  sx={{ 
              display: "flex", alignItems: "center",
              justifyContent: "space-around",

              textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
borderRadius: " 0.5rem",
      fontSize: isMobile ? '18px' : "20px", fontWeight: 'bold', color: "white" }}>
            NEW Tokens
            <FontAwesomeIcon icon={faFire}/>  

            <a  style={{textDecoration: 'none'}} href='https://t.me/pump_fun_monitor' target='blank' rel='noreferrer noopener'>
    <Typography variant="h4"  sx={{ 
      fontSize: isMobile ? '18px' : "20px", fontWeight: 'bold', color: "#0088cc", fontWeight: 'bold' }}>
       TELEGRAM Bot
        <FontAwesomeIcon icon={faTelegram} style={{marginLeft: "10px"}}/>
      </Typography>
      </a>
      </Typography>
    <Container style={{marginTop: "2rem"}} maxWidth="sm">
      <motion.div
        initial={{ y: 300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatedCard style={fade} sx={{ bgcolor: 'black', 
                border: "1px solid white",
                boxShadow: "0px 0px 20px 0px #f15cfa",
                borderRadius:" 0.7rem"
                }}>
          <CardMedia
            component="img"
            height="150"
            image={latestToken.image_uri}
            alt={`${latestToken.name} logo`}
            sx={{ objectFit: 'contain', padding: "5px"}}
          />
          <CardContent sx={{ textAlign: 'center',}}>
            <Typography variant="h6" style={{fontSize: '16px', color: "#f15cfa"}} gutterBottom>
              {latestToken.name} ({latestToken.symbol})
            </Typography>
            <Typography sx={{fontSize: '12px', my: 1 }}>
                Creator: <MuiLink style={{color: 'green'}} href={`https://solscan.io/address/${latestToken.creator}`} target="_blank">
                  {formatCreatorAddress(latestToken.creator)}
                </MuiLink> | 
                <MuiLink style={{color: 'yellow', marginLeft: "10px"}} href={`https://www.pump.fun/profile/${latestToken.creator}`} target="_blank">
                  pump.fun Profile
                  <FontAwesomeIcon icon={faUserAlt} style={{marginLeft: "5px"}}/>
                </MuiLink>
              </Typography>
            <Typography style={{fontSize: "12px"}} color="white">
              {latestToken.description}
            </Typography>
            <Typography sx={{ marginTop: "1rem", fontSize: "12px", my: 1, color: '#f15cfa' }}>
            Market Cap: <span style={{color: 'white'}}>
              {latestToken.usd_market_cap ? formatCurrency(latestToken.usd_market_cap) : "Not Available"}
            </span>
          </Typography>

            <Typography sx={{ fontSize: "12px",my: 1, color: '#f15cfa'  }}>
                Virtual SOL Reserves: <span style={{color: 'white'}}>{convertLamportsToSol(latestToken.virtual_sol_reserves).toFixed(2)} SOL</span>
              </Typography>
              <Typography sx={{ fontSize: "12px", my: 1, color: '#f15cfa'  }}>
                Created: <span style={{color: 'white'}}>{formatDate(latestToken.created_timestamp)}</span>
              </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mb: 2, mt: 2 }}>
              <Button style={{padding: "10px", border: "1px solid white", 
              height: "25px",  textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      

              fontWeight: "bold",
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
            }}  startIcon={<FontAwesomeIcon icon={faXTwitter} />} href={latestToken.twitter} target="_blank">
                Twitter
              </Button>
              <Button style={{padding: "10px", border: "1px solid white", 
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
                backgroundColor: "#0088cc",   
                textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
                height: "25px",         fontWeight: "bold",
            }}  startIcon={<FontAwesomeIcon icon={faTelegram} />} href={latestToken.telegram} target="_blank">
                Telegram
              </Button>
              <Button style={{padding: "10px", border: "1px solid white", 
              backgroundColor: "purple",   
              textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
              height: "25px",       fontWeight: "bold",
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
            }}  startIcon={<FontAwesomeIcon icon={faGlobe} />} href={latestToken.website} target="_blank">
                Website
              </Button>
            </Box>
            <Button
            style={{padding: "10px", border: "1px solid white", 
            backgroundColor: "limegreen",    
            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      

            height: "25px",      fontWeight: "bold",
              fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
          }} href={`https://www.pump.fun/${latestToken.mint}`} target="_blank" fullWidth startIcon={<FontAwesomeIcon icon={faDollarSign} />}>
              Buy on pump.fun
              <img src={pump} alt='Pump logo' style={{ marginLeft: "10px", width: "20px" }} />
            </Button>
          </CardContent>
        </AnimatedCard>
      </motion.div>
    </Container>
    </Container>
    <KingOfTheHill/>
   </Container>
 </Box>
);
}

export default PumpMonitor;
