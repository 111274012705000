import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Container, Typography, Card, CardContent, CardMedia, Button, Grid, Tooltip, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import io from 'socket.io-client';
import { display } from '@mui/system';

// const socket = io('http://localhost:5000', { withCredentials: true });
const socket = io('https://soldegens-server-6bc0c9f4fa58.herokuapp.com', { withCredentials: true });

const Launchpad = () => {
    const [tokens, setTokens] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['userVotes']);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);


useEffect(() => {
  axios.get('/api/projects')
      .then(response => {
        // Map through the response to set the isLiked status based on cookies
        const loadedTokens = response.data.map(token => ({
          ...token,
          isLiked: !!cookies[`vote_${token._id}`]  // Check if a cookie exists for this token
        }));
        setTokens(loadedTokens);
      })
      .catch(error => {
        console.error('Error fetching tokens:', error);
        // handle error appropriately
        toast.error("Failed to load projects");
    });
      socket.on('likeUpdate', (data) => {
        console.log('Like update received', data);
        setTokens(prevTokens => prevTokens.map(token => {
          if (token._id === data.id) {
            if (data.action === 'liked') {
              toast.info(`NEW Vote on ${token.name}`);  // Only show toast if a like was added
            }
            return { ...token, likes: data.likes, isLiked: data.action === 'liked' ? true : token.isLiked };
          }
          return token;
        }));
      });

  return () => {
      socket.off('likeUpdate');
  };
}, [cookies]);


  
    const handleVote = (token) => {
      const updatedTokens = tokens.map(t => {
        if (t._id === token._id) {
          return { ...t, isLiked: !t.isLiked };
        }
        return t;
      });
      setTokens(updatedTokens);
  
      // Check if the token was already voted on
      const voteUpdates = cookies.userVotes || {};
      if (voteUpdates[token._id]) {
        delete voteUpdates[token._id];
        removeCookie(`vote_${token._id}`);
      } else {
        voteUpdates[token._id] = true;
        setCookie(`vote_${token._id}`, true, { path: '/' });
      }
  
      // Send the vote update to the backend
      axios.post('/api/projects/like', { id: token._id, userCookie: `vote_${token._id}` })
        .then(response => console.log(response.data.message))
        .catch(error => console.error('Error handling vote:', error));
    };
  
    return (
      <Container>
<ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}/>

<Typography variant="h4" gutterBottom sx={{ textAlign: "center", marginTop: 4, marginBottom: 2 }}>
        pump.fun - DEGEN LAUNCHPAD <br/>
        <Typography style={{fontSize: "12px", color: "#f15cfa"}}>Do you want to get promoted here? DM us on
        <a style={{color: "white", textDecoration: "none"}} href='https://t.me/soldegens_coin' rel='noreferrer noopener' target='blank'>
        <FontAwesomeIcon icon={faXTwitter} style={{marginLeft: "5px", marginRight: '5px'}}/> 
        </a>
        or via 
        <a style={{marginLeft: "5px", color: "white", textDecoration: "none"}} href='https://t.me/soldegens_coin' rel='noreferrer noopener' target='blank'>Telegram</a>
        <FontAwesomeIcon icon={faTelegram} style={{marginLeft: "10px"}}/> 
            </Typography>
      </Typography>
        <Grid container 
        style={{marginTop: "0.5rem", backgroundColor: 'white', padding: "20px", borderRadius: "1rem",
         flexDirection: isMobile ? 'column' : undefined, display: 'flex'}}
        spacing={2}>
          {tokens.map((token, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              
              <Card sx={{ maxWidth: 345,
              border: "1px solid white",
                                  boxShadow: "0px 0px 20px 0px #f15cfa",
                                  borderRadius:" 0.7rem",
                background: 'linear-gradient(180deg, #121212, rgba(241,92,250,1) 50%)'}}>
                <CardMedia component="img" height="140"
                image={token.image_uri} alt={`Logo di ${token.name}`} />
                <CardContent>
                  <Typography color='black' fontWeight='bold' fontSize='18px' gutterBottom component="div">
                    {token.name} $({token.symbol})
                  </Typography>
                  <Typography color='white' fontWeight='bold'>
                    {token.description}
                  </Typography>
                  <div style={{ marginTop: "0.4rem", display: 'flex', gap: "1rem", alignItems: "center" }}>
  {token.website && (
    <Button
      size="small"
      style={{ backgroundColor: "black", fontSize: isMobile ? '10px' : undefined,  color: "white" }}
      href={token.website}
      target="_blank"
      startIcon={<FontAwesomeIcon icon={faGlobe} />}
    >
      Website
    </Button>
  )}
  {token.twitter && (
    <Button
      style={{ backgroundColor: "black", fontSize: isMobile ? '10px' : undefined, color: "white" }}
      size="small"
      href={token.twitter}
      target="_blank"
      startIcon={<FontAwesomeIcon icon={faXTwitter} />}
    >
      Twitter
    </Button>
  )}
  {token.telegram && (
    <Button
      style={{ backgroundColor: "black", fontSize: isMobile ? '10px' : undefined, color: "white" }}
      size="small"
      href={token.telegram}
      target="_blank"
      startIcon={<FontAwesomeIcon icon={faTelegram} />}
    >
      Telegram
    </Button>
  )}
</div>

                <div style={{marginTop: "0.5rem", display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                  
                  <Typography style={{ 
                    backgroundColor: "purple",
                    padding: "3px", borderRadius: "0.3rem",
                    fontSize: "12px", fontWeight: "bold"}}>
                    $SOL Degens VOTES: {token.likes}
                  </Typography>
                  <Tooltip title="Click to vote/unvote" arrow>
                  <Button
  onClick={() => handleVote(token)}
  style={{
    backgroundColor: token.isLiked ? 'limegreen' : 'grey',
    color: 'white',
    fontSize: "12px",
    fontWeight: "bold"
  }}
>
  {token.isLiked ? 'VOTED' : 'VOTE'} <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faThumbsUp} />
</Button>

                  </Tooltip>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };
  
  export default Launchpad;
  