import React, { useState } from 'react';
import { TextField, Button, Card, Typography, Link } from '@mui/material';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { Container, borderRadius, boxSizing, display, padding } from '@mui/system';
import ohfuck from '../styles/img/ohfuck.png';
import errorImage from '../styles/img/error.png'; // Path to your error image
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';

const CoinChecker = () => {
    const [tokenAddress, setTokenAddress] = useState('');
    const [tokenData, setTokenData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const fetchTokenData = async (address) => {
        setLoading(true);
        setError('');
        setTokenData(null); // Clear previous data
        try {
            const response = await axios.get(`https://api.geckoterminal.com/api/v2/networks/solana/tokens/${address}`);
            setTokenData(response.data.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch token data. Please check the token address and try again.');
            setLoading(false);
            console.error(err);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchTokenData(tokenAddress);
    };

    const handleImageError = (e) => {
        e.target.src = errorImage; // errorImage is the path to your fallback image
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      };

    return (
        <div>
            <Card style={{ padding: '20px', margin: '20px' }}>
                <Typography style={{fontSize: isMobile ? '14px': undefined}} variant="h5">$OLANA Coin Checker
                <FontAwesomeIcon icon={faMagnifyingGlass} style={{marginLeft: "10px"}} />
                </Typography>
                <form style={{display: 'flex', alignItems: "center", gap: "2rem"}} onSubmit={handleSubmit}>
                    <TextField
                        label="Token Address"
                        variant="outlined"
                        fullWidth
                        value={tokenAddress}
                        onChange={(e) => setTokenAddress(e.target.value)}
                        style={{ margin: '10px 0' }}
                    />
                    <Button variant="contained" 
                     style={{boxShadow: "0px 0px 5px 0px #F15CFA", backgroundColor: 'black', color: '#F15CFA', marginLeft: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Check Token'}
                    </Button>
                </form>
                {error && (
                    <Container style={{display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column'}}>
                        <Typography color="error">{error}</Typography>
                        <img src={ohfuck} alt="Error" style={{ marginTop: '20px', width: '200px' }} />
                    </Container>
                )}
                {tokenData && (
                    <>
                        <Typography style={{textAlign: "center", marginTop: "1rem", borderRadius: "0.6rem", backgroundColor: '#f15cfa', padding: "10px", fontSize: "18px", color: 'white'}}>
                            TOKEN FOUND!
                        </Typography>
                        <Typography style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '1rem', textAlign: "center", fontWeight: 'bold', color: '#f15cfa', fontSize: "20px"}}>
                            <span style={{color: "white"}}> {tokenData.attributes.name}</span>
                            <img src={tokenData.attributes.image_url || errorImage} onError={handleImageError} 
                            alt="Token" style={{ borderRadius: '0.5rem', marginLeft: "20px", width: '50px' }} />
                        </Typography>
                    <Container style={{padding: "25px", borderRadius: '0.8rem',
                    marginTop: "2rem",
                    display: 'flex', flexDirection: "column", boxShadow: "0px 0px 20px 0px #f15cfa",
                    backgroundColor: "black",}}>
                        <Typography style={{ display: "flex", alignItems: "center", color: '#f15cfa'}} variant="body1"><strong>Symbol:</strong> <span style={{marginLeft: "10px",  color: "white"}}> {tokenData.attributes.symbol}</span>
                        <div style={{ marginLeft: "15px", display: "flex", alignItems: "center", gap: "0.8rem"}}>
                        <Link  style={{color: "white"}} href={`https://twitter.com/${tokenData.attributes.twitter_handle}`} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </Link>
                        {' '}
                        <Link style={{color: "white"}} href={`https://t.me/${tokenData.attributes.telegram_handle}`} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTelegram} />
                        </Link>
                        </div>
                        </Typography>
                        <Typography style={{fontSize: isMobile ? '12px' :undefined, color: '#f15cfa'}}variant="body1"><strong>Price (USD):</strong> <span style={{color: "white"}}>${tokenData.attributes.price_usd}</span></Typography>
                        <Typography style={{fontSize: isMobile ? '12px' :undefined, color: '#f15cfa'}}variant="body1"><strong>Total Supply:</strong> <span style={{color: "white"}}> {tokenData.attributes.total_supply}</span></Typography>
                        <Typography style={{fontSize: isMobile ? '12px' :undefined, color: '#f15cfa'}}variant="body1"><strong>24h Volume (USD):</strong> <span style={{color: "white"}}> ${tokenData.attributes.volume_usd.h24}</span></Typography>
                        <Typography style={{ fontSize: isMobile ? '12px' : undefined, color: '#f15cfa' }} variant="body1">
                        <strong>Market Cap (USD):</strong>
                        <span style={{ color: "white" }}>
                            ${tokenData.attributes.market_cap_usd ? formatCurrency(tokenData.attributes.market_cap_usd) : "Not Available"}
                        </span>
                        </Typography>

                        <Container style={{display: "flex", justifyContent: "space-between"}}>
                       
                        <Container style={{backgroundColor: 'black', padding: "8px", borderRadius: "0.3rem", marginTop: "1rem", display: "flex", alignItems: "center", gap: "2rem"}}>

                        <Link href={'https://photon-sol.tinyastro.io/@malakia'} target="_blank" rel="noopener noreferrer">
                            <img src='https://photon-sol.tinyastro.io/assets/logo_sol-845bdad2068f3d64590c2c5f543cbfa4df968c202534b3c8c332dd7e8c990eed.svg' alt='photon' style={{height: "20px", objectFit: "contain"}}/>
                        </Link>
                        <Link href='https://t.me/bonkbot_bot?start=ref_qny9w' target="_blank" rel="noopener noreferrer">
                            <img src='https://static.wixstatic.com/media/752d56_9474e2a6478d4248988ce89884bf7991~mv2.png/v1/crop/x_0,y_10,w_1769,h_2131/fill/w_196,h_226,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/output-onlinepngtools.png' alt='photon' style={{height: "50px", objectFit: "contain"}}/>
                        </Link>
                        <Link href={`https://dexscreener.com/solana/${tokenAddress}`} target="_blank" rel="noopener noreferrer">
                            <img src='https://play-lh.googleusercontent.com/e7z4pOkWHbP-g38_Js6c3ZtCfHrGqQKpxpMF-XT4DLGhNa9jMRkGV48hY0s0Xqchq6I' alt='photon' style={{height: "50px", objectFit: "contain"}}/>
                        </Link>
                        </Container>
                        </Container>
                    </Container>
                    </>
                )}
            </Card>
        </div>
    );
};

export default CoinChecker;
