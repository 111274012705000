import React, { useState } from 'react';
import { CardMedia, Tooltip, CardContent, Box, Typography, Button, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faXTwitter }from '@fortawesome/free-brands-svg-icons';
import { faEye, faGlasses, faBriefcase, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { experiences } from '../../../data/experiences';
import wojack1 from "../../../styles/img/wojack1.webp";
import wojack2 from "../../../styles/img/wojak3.jpg";

import SliderModal from '../../../components/fixed/SliderModal';

import { useMediaQuery } from '@mui/material';

function ExperienceCard() {
    const [currentProject, setCurrentProject] = useState(0);
    const mobileWidth = 600;
    const [showDesc, setShowDesc] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);

    const toggleDesc = () => {
        setShowDesc(prev => !prev);
    };

    const toggleOpenImage = () => {
        setOpenImage(prev => !prev);
    };

    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const handleNext = () => {
        setCurrentProject(prev => (prev + 1) % experiences.length);
    };

    const handlePrev = () => {
        setCurrentProject(prev => (prev - 1 + experiences.length) % experiences.length);
    };

    return (
                        <CardContent style={{backgroundColor: '#121212', borderRadius: "1rem",
                        width: isMobile ? undefined : '450px',
                                     display: 'flex', flexDirection: 'column',alignItems: "center"}}>
                                <Typography style={{textAlign: 'center', color: 'white', fontSize: "25px", fontWeight: 'bold' }}>
                                TEAM  <FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon>
                                </Typography>
                                    <Container style={{display: "flex", marginTop: '0.4rem', padding: "20px", flexDirection: "column", textAlign: 'left', gap: "0.4rem" }}>
                                     <Typography style={{fontSize: '12px', color: "white"}}>
                                        <span style={{ color: '#F15CFA',fontWeight: 'bolder'}}>Name:</span> 
                                            {experiences[currentProject].title}</Typography>
                                                <Typography style={{fontSize: '12px', color: "white"}}>
                                                    <span style={{ color: '#F15CFA',fontWeight: 'bolder'}}>Info:</span>  
                                                        {experiences[currentProject].description}
                                                </Typography> 
                                             {showDesc && (
                                            <Typography style={{fontSize: '12px', color: "white"}}>
                                                <span style={{ color: '#F15CFA',fontWeight: 'bolder'}}>Desc:</span>  
                                                {experiences[currentProject].back}
                                            </Typography> 
                                            )}
                                            <Container style={{
                                                display: "flex", justifyContent: 'space-between', padding: '20px',
                                                alignItems: "center" ,borderBottom: '1px solid #F15CFA',
                                                borderTop: '1px solid #F15CFA',  
                                            }}>
                                                <Tooltip title="Open description">
                                                    <Button 
                                                        onClick={toggleDesc}
                                                        style={{width: "auto", boxShadow: "0px 0px 3px 0px #F15CFA", backgroundColor: 'black', fontSize: '9px', color: "#F15CFA", fontWeight: "bolder"}}>
                                                        Read <FontAwesomeIcon icon={faGlasses} style={{marginLeft: '5px'}}></FontAwesomeIcon>
                                                    </Button>
                                                </Tooltip>
                                                <Box style={{
                                                    width: "auto",
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    justifyContent: 'center',
                                                    gap: "0.5rem",
                                                    color: "#F15CFA",
                                                    borderRadius: "0.3rem",
                                                    fontSize: '12px'
                                                }}>
                                                    <Tooltip title="Visit GitHub Repo">
                                                        <a href={experiences[currentProject].link} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon  style={{color: "#F15CFA", boxShadow: "0px 0px 5px 0px #F15CFA", backgroundColor: "#183d3d", padding: '5px', borderRadius: "100%"}} icon={faXTwitter}></FontAwesomeIcon></a>
                                                    </Tooltip>
                                                   
                                                </Box>
                                            </Container>


                                            <Container style={{fontSize: '12px', color: "white", backgroundColor: 'black', padding: "10px",
                                                borderRadius: "0.4rem", boxShadow: '0px 0px 3px 0px #F15CFA', marginTop: "0.7rem", marginBottom: "0.4rem" }}>
                                                <span style={{ color: '#F15CFA',fontWeight: 'bolder'}}>Facts:</span> {experiences[currentProject].tools.map((tool, index) => (
                                                <span key={index} style={{ color: 'white', marginRight: '5px' }}>{tool},</span>
                                                 ))}
                                                </Container>
                                        </Container>   
                            <div 
                                style={{ position: 'relative', gap: "2rem", display: "flex", flexDirection: "column" }} 
                                onClick={toggleOpenImage}
                                onMouseEnter={() => setShowTooltip(true)} 
                                onMouseLeave={() => setShowTooltip(true)}
                            >
                                <CardMedia
                                    image={experiences[currentProject].imageUrl[0]}
                                    alt={experiences[currentProject].title}
                                    component="img"
                                    style={{
                                        width: isMobile ? '100%' : '250px',
                                        objectFit: "contain",
                                        borderRadius: "0.5rem",
                                        boxShadow: '0px 0px 10px 0px #F15CFA'
                                    }}
                                />
                                 <CardMedia
                                    image={wojack1}
                                    component="img"
                                    style={{
                                        width: isMobile ? '100%' : '250px',
                                        objectFit: "contain",
                                        borderRadius: "0.5rem",
                                        boxShadow: '0px 0px 10px 0px #F15CFA'
                                    }}
                                />
                                <CardMedia
                                    image={wojack2}
                                    component="img"
                                    style={{
                                        width: isMobile ? '100%' : '250px',
                                        objectFit: "contain",
                                        borderRadius: "0.5rem",
                                        boxShadow: '0px 0px 10px 0px #F15CFA'
                                    }}
                                />
                                
                            </div>

                            {/* {openImage && (
                                    <SliderModal images={experiences[currentProject].imageUrl} onClose={toggleOpenImage} />
                                            )}
                                    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <h5>Swipe to see more</h5>
                                            <div style={{display: 'flex', justifyContent: "center", gap: "1rem", alignItems: "center"}}>
                                                <Button   style={{boxShadow: "0px 0px 5px 0px #F15CFA", backgroundColor: '#183D3D', color: '#F15CFA', marginLeft: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={handlePrev}>prev.</Button>
                                                <Button   style={{boxShadow: "0px 0px 5px 0px #F15CFA",  backgroundColor: '#183D3D', color: '#F15CFA', marginLeft: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={handleNext}>NEXT</Button>
                                            </div>
                                    </div> */}
                </CardContent>   
    );
}

export default ExperienceCard;
