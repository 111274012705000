import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, {useState, useEffect }from "react";
import { Box, ThemeProvider } from "@mui/material";
import Navbar from "./components/fixed/Navbar";
import theme from "./theme";
import { useMediaQuery } from '@mui/material';
import Homepage from "./pages/homepage/Homepage";
import Footer from "./components/fixed/Footer";
import LoadingScreen from "./components/fixed/LoadingScreen";  // Importa il componente
import Learn from "./pages/Learn";
import LearnDetail from "./components/LearnDetail";
import CoinChecker from "./pages/CoinChecker";
import PumpMonitor from "./pages/PumpMonitor";
import Chat from './components/Chat';
import axios from 'axios';
import "./styles/main/App.scss";

axios.defaults.baseURL = "https://soldegens-server-6bc0c9f4fa58.herokuapp.com";
// axios.defaults.baseURL = 'http://localhost:5000';
axios.defaults.withCredentials = true;


function App () {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [isLoading, setLoading] = useState(true);
  

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;  // Usa il componente
  }

  return (
    <ThemeProvider theme={theme}>
          <BrowserRouter>
      <Navbar/>

      <Box paddingTop='55px'>
      <Routes>
        <Route exact path="/" element={<Homepage/>} />
        <Route exact path='/alpha' element={<Learn/>} />
        <Route exact path='/coin-checker' element={<CoinChecker/>} />
        <Route exact path='/pump-monitor' element={<PumpMonitor/>} />
        <Route exact path='/alpha/:title' element={<LearnDetail/>} />
      </Routes>
      {!isMobile && (
      <Chat />
      )}
      </Box>
      <Footer />
    </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;