import blockchain from "../styles/img/learn/blockchain.png";
import nfts from "../styles/img/learn/nfts.png";
import wallet from "../styles/img/learn/wallet.png";
import smartcontract  from "../styles/img/learn/smartcontract.png";
import web3 from "../styles/img/learn/web3.webp";
import defi from "../styles/img/learn/defi.jpg";
import dao from "../styles/img/learn/dao.png";
import exchange from "../styles/img/learn/exchange.jpg";


const learn = [
    {
        id: 1,
        title: "Photon",
        category: "Bot",
        image: [`https://photon.tinyastro.io/assets/logo-eth-abfa40189e8d25710362e493b84da63dd1cbcd7f8ceafef21829fccf3b6d4c9e.svg`],
        link1: 'https://photon-sol.tinyastro.io/@malakia',
        preview: "Fastest ans safest trading bot for Solana. 100% Goat.",
    },
    {
        id: 2,
        title: "Bonk Bot",
        category: "Bot",
        image: [`https://static.wixstatic.com/media/e4624e_b64258de31d14a4988eceeca15b2a525~mv2.jpg/v1/crop/x_0,y_311,w_1024,h_420/fill/w_480,h_198,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/e4624e_b64258de31d14a4988eceeca15b2a525~mv2.jpg`],
        link1: 'https://t.me/bonkbot_bot?start=ref_qny9w',
        preview: "Probably the most used snipe bot on TG. Real OG, easy to use and fast.",
    },
    {
        id: 3,
        title: "Dex Screener",
        category: "DEX",
        image: [`https://play-lh.googleusercontent.com/e7z4pOkWHbP-g38_Js6c3ZtCfHrGqQKpxpMF-XT4DLGhNa9jMRkGV48hY0s0Xqchq6I`],
        link1: 'https://dexscreener.com/solana',
        preview: "The most powerful DEX/coin checker out there. If you know how to use this, you made 80% of the job.",
    },
    {
        id: 4,
        title: "pump.fun",
        category: "Coin Launcher",
        image: [`https://www.pump.fun/_next/image?url=%2Flogo.png&w=64&q=75`],
        link1: 'https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp',
        preview: "Launch a coin that is instantly tradeable without having to seed liquidity. Deploy a coin for under 2$ in one click!",
    },
    {
        id: 5,
        title: "Solana Trading Bot",
        category: "Bot",
        image: [`https://i.postimg.cc/05mMDYR8/photo-6278112658054037112-c.jpg`],
        link1: 'https://t.me/SolTradingBot?start=vyg5aHnyw',
        preview: "SolTradingBot: The Ultimate High-Speed Sniper and Trading Bot on Solana",
    },
];

export default learn;
