import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Tooltip, TextField, Input, Typography } from '@mui/material';
import learnData from '../../data/learn';
import { Tilt } from 'react-tilt'
import soldegen from "../../styles/img/soldg-logo.png";
import woj from "../../styles/img/chad.png";
import ohfuck from "../../styles/img/ohfuck.png";

import axios from "axios";
import "../../styles/home.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from '@mui/material';
import { motion, useAnimation } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faChartLine, faCoins, faGreaterThan, faLink, faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faInstagram, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import About from '../about/About';
import { Link } from 'react-router-dom';
import Buy from '../../components/Buy';
import { ArtTrack } from '@mui/icons-material';
import Launchpad from './LPADHome';

function Homepage() {
  const [postCount, setPostCount] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const controls = useAnimation();
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
  const [iconColor, setIconColor] = useState('white'); // Initial color

  

  function getRandomLearnTitle() {
    const randomItem = learnData[Math.floor(Math.random() * learnData.length)];
    return randomItem.title;
}
const randomTitle = getRandomLearnTitle();


const fadeSequential = (index) => ({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: index * 0.3,
      duration: 1
    }
  }
});

const shakeEffect = {
  x: [0, -5, 5, -5, 5, 0],
  y: [0, 5, -5, 5, -5, 0],
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
    loop: Infinity
  }
};


const fadeImg = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 2 } }
};

const iconStyle = {
marginRight: "1rem",
fontSize: "25px",
color: iconColor, // Use state for color
};

const hoverEffect = {
scale: 1.5,
transition: {
  type: "spring",
  stiffness: 300
}
};

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval); 
    };
  }, []);
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: "space-between",
      width: isMobile ?  '350px' : undefined, 
    }}>

<style>
          {`
            .Toastify__toast-theme--light {
                background-color: #0E0E0E;
                color: #5CB574;
                box-shadow: 0px 0px 5px 0px #5cb574;
            },
        `}
</style>
    
                      
      

<div style={{height: 'auto', display: "flex", 
gap: isMobile ? '0.5rem' : "2rem",
flexDirection: isMobile ? 'column' : undefined, justifyContent: 'space-between', alignItems: "center"}}>

           {/* <div style={{display: 'flex', marginTop: "0.5rem", fontSize:"12px", alignItems: 'center'}}>
            | LIVE ON PUMP.FUN |
            Time: {currentTime.toLocaleTimeString()} |
           </div> */}
            
 <Typography style={{textAlign: 'center', 
   textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
 borderRadius: "0.3rem", backgroundColor: "red", padding: "5px", fontWeight: "bold", color: "white", fontSize: isMobile ? '10px' : '12px'}}>
    TRY OUR NEW EXCLUSIVE pump.fun <FontAwesomeIcon style={{marginRight: "10px"}} icon={faArrowRightLong}></FontAwesomeIcon>
    
    <Link style={{color: '#E056E9'}} to='/pump-monitor'>TOKEN MONITOR</Link>
 </Typography>
           {/* <h5 style={{color: '#F15CFA'}}> 
   <a style={{fontSize: isMobile ? '10px' : undefined, color: "white"}} href={`/alpha/${randomTitle}`}>Random  Meme
      <FontAwesomeIcon style={{ marginLeft: "8px", marginRight: "5px" }} icon={faCoins}></FontAwesomeIcon>
   </a>
</h5> */}
</div>


      
    <Typography style={{textAlign: 'center',
    marginTop: "1rem", marginBottom: "1rem",
    color: "#F15CFA", fontSize: isMobile ? '18px' : '30px'}}>
    Why are you fading, Anon?
  </Typography>
  <Buy />  

  <Launchpad/>


  <Container // KNOWLEDGE
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "center",
                            boxShadow: 'none',
                            marginTop: "1rem",
                            gap: "1rem",
                            padding: "10px",
                            alignItems: "center",
                            borderRadius: '0.4rem',
                            backgroundColor: 'transparent',
                      }}>
                      <Typography style={{ textAlign: 'center', color: '#f15cfa', fontSize: "25px", fontWeight: 'bold' }}>
                          LINK ROOM
                          <FontAwesomeIcon icon={faLink} style={{ marginLeft: "8px" }}></FontAwesomeIcon>
                      </Typography>
                      <Container
        style={{
            gap: isMobile ? "0.8rem" : "1rem",
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: "center",
            justifyContent: 'center',
        }}>
        {[
            { name: "Follow us on Twitter", icon: faXTwitter, link: "https://twitter.com/sol_degens_" },
            { name: "Join our Telegram", icon: faTelegram, link: "https://t.me/soldegens_coin" },
            { name: "Snipe $DEGEN on pump.fun", icon: faChartLine, link: "https://pump.fun" },
            { name: "Enter our Discord", icon: faDiscord, link: "https://discord.gg/C7NQ5kxsHp" },
            { name: "Follow us on Instagram", icon: faInstagram, link: "https://instagram.com/sol_degenss" },
        ].map((item, index) => (
            <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={fadeSequential(index)}
                whileHover={hoverEffect}
            >
                <Tooltip title={item.name} arrow>
                    <motion.a href={item.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <motion.div
      onHoverStart={() => setIconColor("#f15cfa")} // Change color on hover
      onHoverEnd={() => setIconColor("white")} // Revert color on hover end
      whileHover={hoverEffect}
    >
      <FontAwesomeIcon style={iconStyle} icon={item.icon} />
    </motion.div>
                    </motion.a>
                </Tooltip>
            </motion.div>
        ))}
    </Container>
                </Container>  

  <Tilt options={{ max: isMobile ? '0' : '35' }}>

    <Container style={{display: 'flex',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',    
            flexDirection: isMobile ? 'column' : undefined}} className='bg-home'> 
    <img src={soldegen} style={{width: "300px", objectFit: "contain"}}/>

  <Card
      style={{
        backgroundColor: "transparent",
        color:"white",
        boxShadow: "none",
        padding: '10px',
      }}
    >
                        <h4>Welcome Anon!</h4>
                        <h5 style={{textAlign: 'left'}}>

              SOL $DEGENS is the ultimate Token on the <span style={{fontStyle: "italic"}}>Solana</span> blockchain,<br/>tailored for the memecoins trader
              who knows the game.  <br/> <br/>
              If you’re a crazy ass <span style={{fontStyle: "italic"}}>Shitcoiner</span>, this is where you belong. <br/>

 We packed <span style={{fontStyle: "italic"}}>(maybe)</span> with real utility that keeps things interesting. <br/>
 We're committed  <span style={{fontStyle: "italic"}}>(maybe)</span> to building features that actually matter to you.  <br/> 
 <br/>Our team is  <span style={{fontStyle: "italic"}}>(maybe)</span> always cooking up new ideas that could change the game.<br/>
Be part of a community that’s ready to take the Solana memecoin scene by storm.
<br/> <br/>
Why maybe? Cause it depends on you! SEND IT!

</h5>
            <center>
           <Tooltip title="Sol DEGENS Telegram">
            <a href='https://t.me/soldegens_coin' rel='noreferrer noopener' target='blank'>
          <Button style={{
            backgroundColor: '#10003F',
            height: '25px',
            color: 'white',
            padding: '15px',
            boxShadow: "0px 0px 3px 0px white", 
            fontSize: "12px"
          }} size="small"
    >
      Join the DEGENS!
      <FontAwesomeIcon icon={faTelegram} style={{marginLeft: "10px"}}/>
    </Button>
    </a>
  
    </Tooltip>
    </center>

</Card>  
<img src={woj} style={{height: '300px',  marginTop: isMobile ? '1rem' : '0rem', objectFit: "contain"}}/>
{!isMobile && (<img src={ohfuck} style={{height: '100px',  objectFit: "contain"}}/>
)}
          </Container>
          
          </Tilt>
          <About />


    </div>
  );
}

export default Homepage;
