import dev from "../styles/img/wojack4.jpg";
export const experiences = [
  
    {
      title: "DegenDev",
      description: "He lead the DEGENS",
      back: "Weirdo. All pc and weed. He does all the jobs.",
      tools: ['good guy', 'loves music and animals.'],
      imageUrl: [`${dev}`],
      link: "",
    },
  ];
  