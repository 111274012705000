import React from 'react';
import { Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faPaypal, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
      <Container style={{display: 'flex', gap: '1rem', alignItems: "center", justifyContent: "center", marginTop: "3rem"}}>
        <h6>© 2024 dev by $SOL Degens</h6> -
        <a href='https://twitter.com/sol_degens_' target='blank' rel='noreferrer noopener'>
         <FontAwesomeIcon style={{color: "white"}} icon={faXTwitter}></FontAwesomeIcon>
         </a>
         <a href='https://t.me/soldegens_coin' target='blank' rel='noreferrer noopener'>

        <FontAwesomeIcon style={{color: "white"}}  icon={faTelegram}></FontAwesomeIcon>
        </a>
        <a href='https://t.me/soldegens_coin' target='blank' rel='noreferrer noopener'>

        <FontAwesomeIcon style={{color: "white"}} icon={faDiscord}></FontAwesomeIcon>
        </a>

      </Container>
  );
};

export default Footer;
