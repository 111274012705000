import { AppBar, Hidden, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemText, IconButton, Container } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import logo from "../../styles/img/soltxt.png";
import logo from "../../styles/img/soldg-logo.png";

import iconright from "../../styles/img/soldg-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faListUl, faHome, faGreaterThan, faLaptopCode, faEnvelope, faAddressCard, faCoffee, faBrain, faChartLine, faBagShopping, faSackDollar, faUserNinja, faMagnifyingGlass, faPills } from '@fortawesome/free-solid-svg-icons';
import learn from '../../data/learn';
import { FaChartLine } from 'react-icons/fa';
import pump from "../../styles/img/pump.webp";
function Navbar() {
  const theme = useTheme();
  const [posts, setPosts] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false); 
  const randomIndex = Math.floor(Math.random() * learn.length);
  const randomLearnItem = learn[randomIndex];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };


  return (
    <>
        <AppBar style={{
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'black',
        fontWeight: 'bold',
        height: '60px',
        display: 'flex',
      }} position="fixed">

        <Container style={{
          display: 'flex',
          color: "white",
          fontWeight: 'bold',
          alignItems: "center",
          gap: isMobile ? undefined : "2rem",
          justifyContent: isMobile ? 'space-around' : "center"
        }}>
      
          <Link to='/'>
            <img style={{
              width: isMobile ? '40px' : '40px',
              objectFit: 'contain'
            }} src={isMobile ? iconright : iconright} alt='logo'></img> 
          </Link>

          <Hidden smDown> |
            <Link style={{ color: 'white', display: "flex", alignItems: 'center' }} to=''>BUY $DEGEN
            <FontAwesomeIcon icon={faSackDollar} style={{marginLeft: "10px"}}/>
            </Link> -
            <Link style={{ color: 'white', display: "flex", alignItems: 'center' }} to='/alpha'>Alpha
            <FontAwesomeIcon icon={faUserNinja} style={{marginLeft: "10px"}}/>

            </Link> -
            <Link style={{ color: 'white', display: "flex", alignItems: 'center' }} to='/pump-monitor'>pump.fun Monitor
            <img src={pump} alt='pump' style={{marginLeft: "8px", width: "20px"}}/>
            </Link> |
  
            <Link style={{ color: 'white' }} to='/coin-checker'>Coin Checker
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{marginLeft: "10px"}}/>

            </Link> |
          </Hidden>

          <Box style={{
            width: "auto",
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
            gap: isMobile ? "0.7rem" : '0.8rem',
            color: "white",
            borderRadius: "0.3rem"
          }}>
            <a href="https://twitter.com/sol_degens_" target='_blank' rel='noopener noreferrer'><FontAwesomeIcon color='white' icon={faXTwitter}></FontAwesomeIcon></a> •
            
            <a href='https://t.me/soldegens_coin' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon color='white' icon={faTelegram}></FontAwesomeIcon></a>•
            <a href="https://pump.fun" target='_blank' rel='noopener noreferrer'><FontAwesomeIcon color='white' icon={faChartLine}></FontAwesomeIcon></a>
          </Box>


          {isMobile && (

            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              
              <FontAwesomeIcon icon={faListUl}></FontAwesomeIcon>
            </IconButton>

          )}

        </Container>
        
        <Drawer
          anchor={"right"}
          open={open}
          onClose={handleDrawerToggle}
        >
          <List style={{width: 250, height: "100%",backgroundColor: 'black', borderLeft :"1px solid white"}}>
            <center>
          <img src={logo} alt='logo' style={{width: "200px", alignItems: "center"}}></img>
          </center>
          <Box style={{
            width: "auto",
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
            padding: '5px',
            color: "white",
            borderRadius: "0.3rem"
          }}>
            <h3 style={{color: 'white', textAlign: "center"}}>Menu</h3>

        </Box>
          <ListItem button key="Home" style={{borderBottom: '1px solid #183D3D'}} component={Link} to="/" onClick={handleDrawerToggle}>
            <FontAwesomeIcon style={{marginRight: "10px", color: 'white'}} icon={faHome}></FontAwesomeIcon>
            <ListItemText primary="Home" />
        </ListItem>

        <ListItem button key="Corners" style={{borderBottom: '1px solid #183D3D'}} component={Link} to="/" onClick={handleDrawerToggle}>
            <FontAwesomeIcon style={{marginRight: "10px", color: 'white'}} icon={faGreaterThan}></FontAwesomeIcon>
            <ListItemText primary="Buy $DEGEN" />
          </ListItem>


        <ListItem button key="BUY" style={{borderBottom: '1px solid #183D3D'}} component={Link} to="/alpha" onClick={handleDrawerToggle}>
            <FontAwesomeIcon style={{marginRight: "10px", color: 'white'}} icon={faUserNinja}></FontAwesomeIcon>
            <ListItemText primary="Alpha" />
          </ListItem>

          <ListItem button key="BUY"
          style={{borderBottom: '1px solid #183D3D'}} component={Link} to="/pump-monitor" onClick={handleDrawerToggle}>
            <FontAwesomeIcon style={{marginRight: "10px", color: 'white'}} icon={faPills}></FontAwesomeIcon>
            <ListItemText primary="pump.fun monitor" />
          </ListItem>

          <ListItem button key="Repos" style={{borderBottom: '1px solid #183D3D'}} component={Link} to="/coin-checker" onClick={handleDrawerToggle}>
            <FontAwesomeIcon style={{marginRight: "10px", color: 'white'}} icon={faMagnifyingGlass}></FontAwesomeIcon>
            <ListItemText primary="SOL Coin Checker" />
          </ListItem>

          <Box style={{
               marginTop: "1rem",
               color: "#5CB574",
               background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(56,56,56,0.7587827367275035) 100%)'
            }}>
            {posts.map(post => (
              <ListItem key={post.id} post={post} style={{borderBottom: '1px solid #183D3D'}} component={Link} to={`/corners/${post._id}`} onClick={handleDrawerToggle}>
  <ListItemText primary={post.title} />   
</ListItem>

          ))}
          </Box>

          <h3 style={{color: '#5CB574', textAlign: "center"}}>Random Alpha</h3>
         
              <Box style={{
        background: 'rgb(0,0,0)',
        marginTop: "1rem",
        color: "white",
        background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(241,92,250,0.7587827367275035) 100%)'
      }}>
        <ListItem key={randomLearnItem.id} style={{ borderBottom: '1px solid #183D3D' }} component={Link} to={`/alpha/${randomLearnItem.title}`}  onClick={handleDrawerToggle}>
        <ListItemText primary={randomLearnItem.title} />
         </ListItem>
    </Box>


          </List>
          
        </Drawer>
</AppBar>
</>
  );
}

export default Navbar;
