import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, Link as MuiLink, CardContent, CardMedia, Button, Box } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faDollarSign, faUserAlt, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import fire from "../styles/img/fire.gif";
import format from 'date-fns/format';  // Make sure to install date-fns if not already installed
import pump from "../styles/img/pump.webp";

const AnimatedCard = animated(Card);

const KingOfTheHill = () => {
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://client-api-2-74b1891ee9f9.herokuapp.com/coins/king-of-the-hill');
        // Compare new data with existing data to determine if update is needed
        if (!tokenData || response.data.mint !== tokenData.mint) {
          setTokenData(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);  // Check for new data every 5 seconds
    return () => clearInterval(interval);
  }, [tokenData]);

  if (loading) return <p>Loading...</p>;

  const formatCreatorAddress = (address) => {
    return address ? `${address.slice(0, 4)}...${address.slice(-4)}` : '';
  };

  const convertLamportsToSol = (lamports) => {
    return lamports / 1e9; // Convert lamports to SOL
  };

  const formatDate = (timestamp) => {
    return format(new Date(timestamp), "PPpp"); // Using date-fns to format the timestamp
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <Container style={{display: 'flex', flexDirection: "column",}}>
    <Typography variant="h4"  sx={{ 
              display: "flex", alignItems: "center",
              justifyContent: "space-around",                    textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
borderRadius: " 0.5rem",
      fontSize: isMobile ? '18px' : "16px", fontWeight: 'bold', color: "white" }}>
            King of the Hills   
            <FontAwesomeIcon icon={faCrown}/>  

            <a  style={{textDecoration: 'none'}} href='https://t.me/pump_fun_koth' target='blank' rel='noreferrer noopener'>
      <Typography variant="h4"  sx={{ 
      fontSize: isMobile ? '18px' : "20px", fontWeight: 'bold', color: "#0088cc" }}>
       TELEGRAM Bot
                <FontAwesomeIcon icon={faTelegram} style={{marginLeft: "10px"}}/>
      </Typography>
      </a>
      </Typography>
      <Container style={{marginTop: "2rem"}} maxWidth="sm">
          <AnimatedCard style={fade} sx={{ bgcolor: 'black',
                border: "1px solid white",
                boxShadow: "0px 0px 20px 0px #f15cfa",
                borderRadius:" 0.7rem",
         width: '100%' }}>
            <CardMedia
              component="img"
              height="150"
              image={tokenData.image_uri}
              alt={`${tokenData.name} logo`}
              sx={{ objectFit: 'contain', padding: "5px"}}
            />
            <CardContent sx={{ textAlign: 'center'}}>
            <Typography variant="h6" style={{fontSize: '16px', color: "#f15cfa"}} gutterBottom>                {tokenData.name} ({tokenData.symbol})
              </Typography>
              <Typography sx={{fontSize: '12px', my: 1 }}>
                Creator: <MuiLink style={{color: 'green'}} href={`https://solscan.io/address/${tokenData.creator}`} target="_blank">
                  {formatCreatorAddress(tokenData.creator)}
                </MuiLink> | 
                <MuiLink style={{color: 'yellow', marginLeft: "10px"}} href={`https://www.pump.fun/profile/${tokenData.creator}`} target="_blank">
                  pump.fun Profile
                  <FontAwesomeIcon icon={faUserAlt} style={{marginLeft: "5px"}}/>
                </MuiLink>
              </Typography>
            <Typography style={{fontSize: "12px"}} color="white">
              {tokenData.description}
            </Typography>
            <Typography sx={{ fontSize: '12px', my: 1 }}>
              Market Cap: <span style={{ color: 'white' }}>{formatCurrency(tokenData.usd_market_cap)}</span>
            </Typography>
            <Typography sx={{ fontSize: "12px",my: 1, color: '#f15cfa'  }}>
                Virtual SOL Reserves: <span style={{color: 'white'}}>{convertLamportsToSol(tokenData.virtual_sol_reserves).toFixed(2)} SOL</span>
              </Typography>
              <Typography sx={{ fontSize: "12px", my: 1, color: '#f15cfa'  }}>
                Created: <span style={{color: 'white'}}>{formatDate(tokenData.created_timestamp)}</span>
              </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mb: 2, mt: 2 }}>
              <Button style={{padding: "10px", border: "1px solid white", 
              fontWeight: "bold",        
              textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
              height: "25px",
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
            }}  startIcon={<FontAwesomeIcon icon={faXTwitter} />} href={tokenData.twitter} target="_blank">
                Twitter
              </Button>
              <Button style={{padding: "10px", border: "1px solid white", 
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
                backgroundColor: "#0088cc",
                textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
                height: "25px",            fontWeight: "bold",
            }}  startIcon={<FontAwesomeIcon icon={faTelegram} />} href={tokenData.telegram} target="_blank">
                Telegram
              </Button>
              <Button style={{padding: "10px", border: "1px solid white", 
              backgroundColor: "purple",   
              
              textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
              height: "25px",      fontWeight: "bold",
                fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
            }}  startIcon={<FontAwesomeIcon icon={faGlobe} />} href={tokenData.website} target="_blank">
                Website
              </Button>
            </Box>
            <Button
            style={{padding: "10px", border: "1px solid white", 
            backgroundColor: "limegreen",     
            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
            height: "25px",           fontWeight: "bold",
              fontSize: "14px", color: "white", boxShadow: "0px 0px 5px 0px white",
          }} href={`https://www.pump.fun/${tokenData.mint}`} target="_blank" fullWidth startIcon={<FontAwesomeIcon icon={faDollarSign} />}>
              Buy on pump.fun
              <img src={pump} alt='Pump logo' style={{ marginLeft: "10px", width: "20px" }} />
            </Button>
            </CardContent>
          </AnimatedCard>
      </Container>
    </Container>
  );
};

export default KingOfTheHill;
