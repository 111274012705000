import { Container, Card, Typography, Button, Tooltip } from '@mui/material'
import { useMediaQuery } from '@mui/material';
import wojack2 from "../../styles/img/wojack2.webp";
import it from "../../styles/img/it.png";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import corner from "../../styles/img/corner.png";
import ExperienceCard from './components/ExperienceCard';
import resume from "../../data/resume.pdf";
import logo from '../../styles/img/soldg-logo.png';
import cornerright from "../../styles/img/cornerright.png";
import { motion, useAnimation } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faChartPie, faDownload, faLaptopCode, faLink, faRestroom } from '@fortawesome/free-solid-svg-icons';
import { 
    faDiscord, faEthereum, faFigma, faGit, faStackOverflow,
    faCodepen, faNode, faChrome, 
    faHtml5, faJs, faNpm, faReact, faSass, faCss3Alt, 
    faXTwitter,
    faTelegram,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import {faBrain} from '@fortawesome/free-solid-svg-icons';
import Timeline from './components/Timeline';
import { Tilt } from 'react-tilt';
import { LANGUAGES } from '../../constants/index';

function About() {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  
    const reflectionStyle = {
        textShadow: '8px 18px 7px rgba(255, 255, 255, 0.15)'
    };

        return (
                <Container style={{ /// CONTAINER MAIN ABOUT
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    gap: '1rem',
                }}>
                  <Container style={{display: "flex", 
                  gap: "5rem", flexDirection: isMobile ? 'column' : undefined,
                  border: "1px solid white",
                  padding: "20px",
                  boxShadow: "0px 0px 20px 0px #f15cfa",
                  borderRadius:" 0.7rem",
                  justifyContent: 'space-between'}}>
                  <Card  /// CARD BIO
                      style={{
                          color:"white",
                          padding: "10px",
                          width: isMobile ? undefined : '700px',
                      }}>
                        <section style={{
                          display: 'flex',
                          justifyContent: isMobile ? 'center' : 'space-around',
                          alignItems: 'center',
                          flexDirection: isMobile ? 'column' : undefined
                        }}>
                      <Typography style={{
                          fontSize: "20px",
                          color: "#f15cfa",
                          marginBottom: '1rem',
                          fontWeight: 'bolder',
                          textAlign: "center",
                      }}>
                        - Made by Degens for DEGENS - 
                      </Typography>
                     
                      </section>
                      <div style={{border: '1px solid white', width: "100%"}}></div>
                       
                         <Tilt options={{ max: isMobile ? '0' : '35' }}>

                      </Tilt>

<h5 style={reflectionStyle}>
                        Our community is our core. Built by degens for degens, we understand what makes  <br/>
                        the memecoin market tick. Amidst the frenzied highs and lows, here lies a space <br/>
                        where those who dare to challenge their fate every day find solace. <br/><br/>
                        We're not just developers and traders; <br/>
                        we're poets of the blockchain, dreamers with data, <br/>navigating the stormy seas of crypto with a quiet resolve.<br/> 
                        Here, each tick is a heartbeat, each transaction a breath. <br/>
                        <br/> In this relentless pursuit, we find a bittersweet romance.<br/> 
                        We're committed to transparency, constant innovation, and—most importantly—creating <br/>
                        a vibrant ecosystem where every member can thrive amid the chaos. <br/><br/>
But that’s not all—we’re constantly evolving.  <br/>
Our development team is deep in the game, <br/> working in the shadows to enhance the platform, <br/> 
crafting surprises that propel our daring ventures into new territories.  <br/><br/>
With SOL DEGENS, you're always at the forefront <br/> of the next big thing in the memecoin space, <br/>where each new feature is a whisper of potential. <br/>
<br/> Get ready to experience the pulse of Solana’s memecoin scene in a way that’s tailored  <br/>
just for you, the true degen, where skill meets chance, <br/>and every moment is a dance with destiny.
                        </h5>
                        <img src={wojack2} alt='mehi' style={{
                            width: isMobile ? '100%' : "400px",
                            marginTop: '1rem',
                            borderBottom: "1px solid grey",
                            objectFit: 'contain',
                            }}></img>
                  </Card>

                  <ExperienceCard/>
                  </Container>
                     


<img src={logo} alt='logo' style={{width: isMobile ? '100%' : "500px", objectFit: 'contain'}}/>

                <Container style={{
                  display: "flex",
                  gap: "1rem",
                  padding: "20px",
                  flexDirection: isMobile ? 'column' : undefined,
                  justifyContent: isMobile ? 'center' : 'space-around',
                }}>
                      {/* <Timeline/> */}
                      </Container>
   
                    
          </Container>
  )
}

export default About;



