import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useMediaQuery } from '@mui/material';
import { Container, border, borderRadius, boxSizing, display } from '@mui/system';
import React from 'react'
import wo from "../styles/img/stfc.png";
import stick4 from "../styles/img/stick4.png";
import fade from '../styles/img/stick2.png'
function Buy() {

  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  return (
    <Container style={{display: "flex", alignItems: "center",  
    flexDirection: isMobile ? 'column' : undefined,
    marginTop: "0.5rem",
    background:  isMobile ? 'linear-gradient(180deg, rgba(0,0,0,0.7867939412092962) 0%, rgba(241,92,250,1) 100%)' : 'linear-gradient(90deg, rgba(0,0,0,0.7867939412092962) 0%, rgba(241,92,250,1) 100%)',
    borderRadius: "1rem", boxShadow: '0px 0px 20px 0px rgba(241,92,250,1) ', border: '1px solid white',
    justifyContent: "space-around"}}>


    <Container style={{display: 'flex', padding: "10px",
    alignItems: "center", justifyContent: "center",
    flexDirection: 'column',
    gap: "0.5rem"}}>
      
      <a  style={{textDecoration: 'none'}} target='blank' rel='noreferrer noopener' href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp'>
      <Typography style={{textAlign: 'center', color: "white", fontSize: isMobile ? '14px' : '14px'}}>
    CA: <span style={{
          textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      

      color: '#F15CFA'}}>3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp</span> 
 </Typography>
 </a>
  <Typography style={{
              textAlign: "center",
                                textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
                color: "white", fontSize: isMobile ? '18px' : "30px", fontWeight: "bold"}}>
            <a style={{color: "#f15cfa"}} href='https://t.me/soldegens_coin' rel='noreferrer noopener' target='blank'>$DEGEN</a> is now LIVE!<FontAwesomeIcon icon={faFire} style={{marginLeft: "5px"}}/>
            <br/ >
        BUY on ➜ <a  style={{color: "limegreen"}}  href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp' rel='noreferrer noopener' target='blank'>pump.fun</a>
        </Typography>
        <div style={{display: 'flex', alignItems: 'center', gap: isMobile ? '1.5rem': "3rem"}}>
        <a  style={{color: "limegreen"}}  href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp' rel='noreferrer noopener' target='blank'>
        <img src={wo} alt='wo' style={{
          boxShadow: "0px 0px 15px 0px #f15cfa", border: "1px solid white", 
          borderRadius: "0.5rem",
          height: isMobile ?  "80px" : '100px', objectFit: 'contain'}}/>
          </a>

          <a  style={{color: "limegreen"}}  href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp' rel='noreferrer noopener' target='blank'>

<img src={fade} alt='wo' style={{
          height: isMobile ?  "80px" : '100px', objectFit: 'contain'}}/>
</a>
<a  style={{color: "limegreen"}}  href='https://www.pump.fun/3apN9Bw3weFhYjz2ebjen5aD5cqkEeM6UqCjAhW4Qhjp' rel='noreferrer noopener' target='blank'>

                  <img src={stick4} alt='wo' style={{
          boxShadow: "0px 0px 15px 0px #f15cfa", border: "1px solid white", 
          borderRadius: "0.5rem",
          height: isMobile ?  "80px" : '100px', objectFit: 'contain'}}/>
</a>
          </div>
    </Container>

    <Container style={{display: 'flex', padding: "20px",
    alignItems: "center", justifyContent: "center",
    flexDirection: 'column',}}>
      
  <Typography style={{
textAlign: isMobile ? 'center' : undefined,
    textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 2px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",      
                color: "white", fontSize: isMobile ? '14px' : "16px", fontWeight: "bold"}}>
           - Fair launch on pump. DEV hold -1% <br/><br/>
           - Actual roadmap/utilities [Bots, Alpha, Tools] <br/><br/>
           - $DEGEN is for the community.  <br/> <br/>
           $DEGEN is the call for anyone who's ever dabbled in a $SOL memecoin or dreamed of their big x1000 win. This is our homage to the wild and wonderful world of meme and shitcoins—a celebration for every mad trader, wild devs, and every noob and regular joe out there.
        </Typography>
    </Container>
    </Container>
  )
}

export default Buy;
