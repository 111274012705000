import React from "react";
import "../src/styles/main/index.css";
import App from "./App";
import { createRoot } from 'react-dom/client'; // Import createRoot

// Find the root element
const rootElement = document.getElementById("root");

// Create a root
const root = createRoot(rootElement);

// Render your app
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
